// main
var $ = jQuery;
$(function() {
	// Mobile menu
	$('#burger').on('click', function(e) {
		e.preventDefault();
		$('body').toggleClass('show-nav-menu');
	});
	$('#nav-menu-blanker, #nav-menu-close').on('click', function(e) {
		e.preventDefault();
		$('body').removeClass('show-nav-menu');
	}); 

	// smooth scroll anchor links
	$('a[href^=#]').smoothScroll();

	// add 'Next Page' and 'Prev Page' buttons to pages with siblings
	function siblingPagination() {
		var ul = $('.menu-block-aesa_core_sidebar_nav ul');
		if(!ul.length) return;

		var this_page = ul.find('li.active');
		//if(!this_page.length) return;

		var pager = $('<div class="sibling-pager" />').appendTo($('#content'));

		if(this_page.length) {

			var prev_page = this_page.prev();
			while(prev_page.find("span.nolink").length > 0) {
				prev_page = prev_page.prev();
			}

			if(prev_page.length) {
				$('<a />')
					.addClass('prev-page')
					.attr('href', prev_page.find('a').attr('href'))
					.text('Prev Page')
					.prepend('<i />')
					.appendTo(pager);
			}
		}
		var next_page;
		if(this_page.length) {
			next_page = this_page.next();
			while(next_page.find("span.nolink").length > 0) {
				next_page = next_page.next();
			}
		}
		else {
			next_page = ul.find('li').eq(0);
		}

		if(next_page.length) {
			$('<a />')
				.addClass('next-page')
				.attr('href', next_page.find('a').attr('href'))
				.text('Next Page')
				.append('<i />')
				.appendTo(pager);
		}

		if (this_page.is(':first-child')) {
			var href = $("#main-menu").find("li.active-trail a").attr("href");
			$('<a />')
				.addClass('prev-page')
				.attr('href', href)
				.text('Prev Page')
				.prepend('<i />')
				.appendTo(pager);
		}
	}
	if($('.menu-block-aesa_core_sidebar_nav').length) {
		siblingPagination();
	}

	// resource page mobile filter toggle
	$('.show-filter a').on('click', function(e) {
		e.preventDefault();
		$('.resource-result-sidebar .filter').slideDown();
		$('.show-filter').slideUp();
		$('.resource-result-sidebar').addClass('expanded')
	});
	$('.hide-filter a').on('click', function(e) {
		e.preventDefault();
		$('.resource-result-sidebar .filter').slideUp();
		$('.show-filter').slideDown();
		$('.resource-result-sidebar').removeClass('expanded')
	});

});